.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 1;

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__contents {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 20px;
}

.nav__contents__logo {
  flex: 1;
}

.nav__contents__menu {
  display: flex;
  align-items: center;
  text-align: center;
}

.nav__contents__menu__span {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;
  text-align: center;
  padding: 0 20px;
}

.nav__contents__menu > span:hover {
  opacity: 1;
  transform: scale(1.05);
}

.nav__black {
  background-color: #111;
  opacity: 1;
}

.nav__logo {
  position: fixed;
  top: 15px;
  width: 150px;
  object-fit: contain;
  padding-left: 30px;
  cursor: pointer;
}

/* Phones and Tablet*/
@media (max-width: 799px) {
  .nav__contents {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
  }

  .nav__contents__logo {
    flex: none;
  }

  .nav__contents__menu {
    display: flex;
    flex-direction: row;
    flex: none;
    justify-content: left;
    align-items: center;
    text-align: center;
    /* margin: 0 auto; */
    padding: 0;
    overflow-x: scroll;
    transition: all ease 0.5s;
  }

  .nav__contents__menu::-webkit-scrollbar {
    display: none;
  }

  .nav__contents__menu__span {
    display: flex;
    cursor: pointer;
    opacity: 0.9;
    padding: 0 20px;
    font-size: 1rem;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .nav__contents__menu > span:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  .nav__black {
    background-color: #111;
    opacity: 1;
  }

  .nav__logo {
    position: relative;
    top: 0;
    left: none;
    width: auto;
    object-fit: contain;
    padding-left: 0px;
    cursor: pointer;
    width: 150px;
  }
}

/* Tablet and small laptop */
@media (min-width: 800px) {
  /* .nav__contents {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
  }

  .nav__contents__logo {
    flex: none;
  }

  .nav__contents__menu {
    display: flex;
    flex-direction: row;
    flex: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
  }

  .nav__contents__menu > span {
    cursor: pointer;
    opacity: 0.9;
    padding: 0;
  }

  .nav__contents__menu > span:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  .nav__black {
    background-color: #111;
    opacity: 1;
  }

  .nav__logo {
    position: relative;
    top: 0;
    left: 0;
    width: 120px;
    object-fit: contain;
    padding-left: 0px;
    cursor: pointer;
  } */
}

/* Laptop */
@media (max-width: 992px) {
  /* .nav__contents {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
  }

  .nav__contents__logo {
    flex: none;
  }

  .nav__contents__menu {
    display: flex;
    flex-direction: row;
    flex: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
  }

  .nav__contents__menu > span {
    cursor: pointer;
    opacity: 0.9;
    padding: 0;
  }

  .nav__contents__menu > span:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  .nav__black {
    background-color: #111;
    opacity: 1;
  }

  .nav__logo {
    position: relative;
    top: 0;
    left: 0;
    width: 120px;
    object-fit: contain;
    padding-left: 0px;
    cursor: pointer;
  } */
}

/* Desktop */
@media (max-width: 1200px) {
  /* .nav__contents {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
  }

  .nav__contents__logo {
    flex: none;
  }

  .nav__contents__menu {
    display: flex;
    flex-direction: row;
    flex: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    
  }

  .nav__contents__menu > span {
    cursor: pointer;
    opacity: 0.9;
    text-align: center;
    padding: 0;
  }

  .nav__contents__menu > span:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  .nav__black {
    background-color: #111;
    opacity: 1;
  }

  .nav__logo {
    position: relative;
    top: 0;
    left: 0;
    width: 120px;
    object-fit: contain;
    padding-left: 0px;
    cursor: pointer;
  } */  
}
