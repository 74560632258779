@import url("https://fonts.googleapis.com/css?family=Poppins");

* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif, lighter, bolder;
}

.app {
  background-color: #111;
}
