* {
  margin: 0;
}

.banner {
  color: #111;
  background-color: #858289;
}

.banner__frame {
  display: flex;
  height: auto;
}

.banner__content {
  flex: 1;
  padding: 150px 50px;
  align-items: center;
  justify-content: center;
}

.banner__image {
  position: relative;
  flex: 2;
  justify-content: left;
  text-align: left;
}

/* .banner__image__item {
  max-width: 700px;
  bottom: 0;
  position: absolute;
} */

.banner__mensaje {
  font-size: 4rem;
  font-weight: 700;
  padding-bottom: 20px;
  line-height: 4.2rem;
}

.banner__submensaje {
  font-size: 2rem;
}

.banner__contacto {
  margin-top: 40px;
  padding: 25px;
  font-weight: 600px;
  font-size: 1.2rem;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background-color: #a98e7e;
  transition: all 0.5s ease;
}

.banner__contacto:hover {
  background-color: lightgray;
}

.features__title > h2 {
  color: white;
  text-align: center;
  font-weight: 400;
  padding-top: 50px;
  font-size: 1.5rem;
}

.features {
  display: flex;
  color: white;
  justify-content: center;
  align-items: top;
  padding: 50px 0;
}

.features__container {
  width: 250px;
  padding: 20px;
  transition: all 0.5s ease;
}

.features__container:hover {
  transform: scale(1.05);
}

.features__img {
  width: 100%;
}

.features__desc {
  padding: 20px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 200;
}

.we {
  padding: 80px 40px;
  background-color: whitesmoke;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.we > h2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
}

.we__portfolio {
  padding-top: 40px;
}

.we__portfolio > h3 {
  font-weight: 500;
  font-size: 2rem;
}

.portfolio__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  padding: 50px;
  height: 80vh;
}

.portfolio__left {
  flex: 1;
  padding: 0 50px;
  max-width: 900px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.portfolio__left img {
  width: 150px;
  padding: 20px 0;
}

.portfolio__left > p {
  font-size: 1.2rem;
  font-weight: 400;
  max-width: 450px;
}

.portfolio__left > h3 {
  padding-top: 20px;
  font-weight: 600;
}

.portfolio__right {
  flex: 1;
  padding: 20px;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.portfolio__right img {
  height: auto;
  max-width: 900px;
/*   object-fit: cover; */
  /* transition: all 1s ease; */
  border-radius: 20px;
}

/* .portfolio__right img:hover {
  transform: scale(1.1);
  transition: all 1s ease;
} */

.portfolio__left button {
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background-color: #a98e7e;
  transition: all 0.5s ease;
}

.portfolio__left button:hover {
  background-color: lightgray;
}

.portfolio__left button > a {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
  color: #111;
}

.team__title > h2 {
  color: white;
  text-align: center;
  font-weight: 400;
  padding-top: 50px;
  font-size: 1.5rem;
}

.team {
  display: flex;
  color: white;
  justify-content: center;
  align-items: top;
  padding: 50px 0;
}

.team__container {
  width: 250px;
  padding: 20px;
  transition: all 0.5s ease;
  justify-content: center;
  text-align: center;
}

.team__container:hover {
  transform: scale(1.1);
}

.team__img {
  width: 100%;
}

.team__name {
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}

.team__title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
}

.team__desc {
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: lighter;
}

.team__container button {
  padding: 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background-color: #a98e7e;
  transition: all 0.5s ease;
}

.team__container button:hover {
  background-color: lightgray;
}

.team__container button > a {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
  color: #111;
}

.calendly {
  color: white;
  text-align: center;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}

.calendly > h3 {
  font-size: 1.8rem;
  font-weight: 600;
  padding-bottom: 20px;
  margin-top: 80px;
}

.calendly__wsp {
  font-size: 1.2rem;
  font-weight: 400;
  padding-bottom: 10px;
}

.calendly__email {
  font-size: 1.2rem;
  font-weight: 400;
}

.footer {
  padding: 40px 0;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Phones and Tablet*/
@media (max-width: 799px) {
  .banner {
    color: #111;
    background-color: #858289;
  }

  .banner__frame {
    display: grid;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .banner__content {
    flex: 1;
    padding: 170px 50px 50px 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .banner__image {
    position: relative;
    flex: 1;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0 auto;
  }

  .banner__image__item {
    width: 90%;
    height: auto;
    bottom: 0;
    position: relative;
  }

  .banner__mensaje {
    font-size: 2.5rem;
    font-weight: lighter;
    padding: 40px 0;
    line-height: 2.5rem;
  }

  .banner__submensaje {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-top: 10px;
  }

  .banner__contacto {
    margin-top: 40px;
    padding: 25px;
    font-weight: 600px;
    font-size: 1.2rem;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    background-color: #a98e7e;
    transition: all 0.5s ease;
  }

  .banner__contacto:hover {
    background-color: lightgray;
  }

  .features__title > h2 {
    color: white;
    text-align: center;
    font-weight: 400;
    padding-top: 50px;
    font-size: 1.5rem;
  }

  .features {
    display: grid;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: top;
    padding: 50px 0;
  }

  .features__container {
    width: 250px;
    padding: 20px;
    transition: all 0.5s ease;
  }

  .features__container:hover {
    transform: scale(1.05);
  }

  .features__img {
    width: 100%;
  }

  .features__desc {
    padding: 20px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 200;
  }

  .portfolio__container {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
  }

  .portfolio__left {
    flex: 1;
    padding: 10px;
    /* width: 100%; */
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: grid;
    flex-direction: column;
    padding-top: 40px;
  }

  .portfolio__left img {
    width: 150px;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .portfolio__left > p {
    font-size: 1.2rem;
    font-weight: 400;
    width: auto;
  }

  .portfolio__left > h3 {
    padding-top: 20px;
    font-weight: 600;
  }

  .portfolio__right {
    flex: 1;
    padding: 20px 0;
    justify-content: center;
    width: auto;
  }

  .portfolio__right img {
    height: 100%;
    width: 100%;
  }

  .portfolio__left button {
    margin-top: 20px;
    padding: 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color: #a98e7e;
    transition: all 0.5s ease;
  }

  .portfolio__left button:hover {
    background-color: lightgray;
  }

  .portfolio__left button > a {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
    color: #111;
  }

  .team {
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }

  .footer {
    padding: 40px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .calendly > h3 {
    margin-top: 200px;
  }
}

/* Tablet and small laptop */
@media (min-width: 1150px) {

  .banner__image__item {
    max-width: 650px;
    bottom: 0;
    position: absolute;
  }

  .portfolio__right img {
    height: 100%;
    max-width: 400px;
  }

  /* .banner {
    color: #111;
    background-color: #858289;
  }

  .banner__frame {
    display: grid;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  .banner__content {
    flex: 1;
    padding: 170px 50px 50px 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .banner__image {
    position: relative;
    flex: 1;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0 auto;
  }

  .banner__image__item {
    width: 90%;
    height: auto;
    bottom: 0;
    position: relative;
  }

  .banner__mensaje {
    font-size: 2.5rem;
    font-weight: lighter;
    padding-bottom: 20px;
  }

  .banner__submensaje {
    font-size: 2rem;
  }

  .banner__contacto {
    margin-top: 40px;
    padding: 25px;
    font-weight: 600px;
    font-size: 1.2rem;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    background-color: #a98e7e;
    transition: all 0.5s ease;
  }

  .banner__contacto:hover {
    background-color: lightgray;
  }

  .features__title > h2 {
    color: white;
    text-align: center;
    font-weight: 400;
    padding-top: 50px;
    font-size: 1.5rem;
  }

  .features {
    display: grid;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: top;
    padding: 50px 0;
  }

  .features__container {
    width: 250px;
    padding: 20px;
    transition: all 0.5s ease;
  }

  .features__container:hover {
    transform: scale(1.05);
  }

  .features__img {
    width: 100%;
  }

  .features__desc {
    padding: 20px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 200;
  }

  .portfolio__container {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
  }

  .portfolio__left {
    flex: 1;
    padding: 10px;
    max-width: 300px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: grid;
    flex-direction: column;
    padding-top: 40px;
  }

  .portfolio__left img {
    width: 150px;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .portfolio__left > p {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .portfolio__left > h3 {
    padding-top: 20px;
    font-weight: 600;
  }

  .portfolio__right {
    flex: 1;
    padding: 20px 0;
    justify-content: center;
  }

  .portfolio__right img {
    height: 100%;
    width: 100%;
  }

  .portfolio__left button {
    margin-top: 20px;
    padding: 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color: #a98e7e;
    transition: all 0.5s ease;
  }

  .portfolio__left button:hover {
    background-color: lightgray;
  }

  .portfolio__left button > a {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 400;
    color: #111;
  }

  .team {
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
  }

  .footer {
    padding: 40px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .calendly > h3 {
    margin-top: 200px;
  } */
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1300px) {
}
